import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Seo = makeShortcode("Seo");
const LineTextLine = makeShortcode("LineTextLine");
const Container = makeShortcode("Container");
const Row = makeShortcode("Row");
const Col = makeShortcode("Col");
const ImageBed = makeShortcode("ImageBed");
const Link = makeShortcode("Link");
const Picture = makeShortcode("Picture");
const AndManyMore = makeShortcode("AndManyMore");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Seo title={props.pageContext.frontmatter.title} description={props.pageContext.frontmatter.description} mdxType="Seo" />
    <LineTextLine title={props.pageContext.frontmatter.title} mdxType="LineTextLine" />
    <Container mdxType="Container">
  <Row mdxType="Row">
    <Col p5="true" mdxType="Col">
      <ImageBed mdxType="ImageBed">
        <Link to="/men-s-portraits/vasiliy" mdxType="Link">
          <Picture name="Vasiliy" modelName="Vasiliy Lebedev" place="Barcelona" filename="Vasiliy-1-2.jpg" mdxType="Picture" />
        </Link>
      </ImageBed>
    </Col>
    <Col p5="true" mdxType="Col">
      <ImageBed mdxType="ImageBed">
        <Link to="/men-s-portraits/alexander" mdxType="Link">
          <Picture name="Aleksandr Stepanenko" modelName="Aleksandr Stepanenko" place="Barcelona" filename="stepanenko_ap_andrey_bondarenko_002.jpg" mdxType="Picture" />
        </Link>
      </ImageBed>
    </Col>
    <Col p5="true" mdxType="Col">
      <ImageBed mdxType="ImageBed">
        <Link to="/stas-ans-luisa" mdxType="Link">
          <Picture name="Stas and Luisa" modelName="Stas Stanis and Luisa Gromova" place="Moscow" filename="Louisa-and-Stas-photography-by-andrey-bond.jpg" mdxType="Picture" />
        </Link>
      </ImageBed>
    </Col>
  </Row>
  <Row mdxType="Row">
    <Col p5="true" mdxType="Col">
      <ImageBed mdxType="ImageBed">
        <Link to="/women-s-portraits/questioning-my-faith" mdxType="Link">
          <Picture name="Questioning My Faith" modelName="Daria. Model agency 'Divino Espejo'" place="Barcelona" filename="questioning_my_faith005.jpg" mdxType="Picture" />
        </Link>
      </ImageBed>
    </Col>
    <Col p5="true" mdxType="Col">
      <ImageBed mdxType="ImageBed">
        <Link to="/women-s-portraits/lady-in-red" mdxType="Link">
          <Picture name="Lady in Red" modelName="Daria. Model agency 'Divino Espejo'" place="Barcelona" filename="Lady_in_Red_7_of_21.jpg" mdxType="Picture" />
        </Link>
      </ImageBed>
    </Col>
  </Row>
  <Row mdxType="Row">
    <Col p5="true" mdxType="Col">
      <ImageBed mdxType="ImageBed">
        <Link to="/men-s-portraits/luca" mdxType="Link">
          <Picture name="Luca" modelName="Luca De Flaviis" place="Barcelona" filename="Luca_photography_by_Andrey_Bond-8.jpg" mdxType="Picture" />
        </Link>
      </ImageBed>
    </Col>
    <Col p5="true" mdxType="Col">
      <ImageBed mdxType="ImageBed">
        <Link to="/men-s-portraits/abdel" mdxType="Link">
          <Picture name="Abdel" modelName="Abdelkhalek Jnah" place="Barcelona" filename="Abdel_photography_by_Andrey_Bond-3.jpg" mdxType="Picture" />
        </Link>
      </ImageBed>
    </Col>
    <Col p5="true" mdxType="Col">
      <ImageBed mdxType="ImageBed">
        <Link to="/men-s-portraits/jorge" mdxType="Link">
          <Picture name="Jorge" modelName="Jorge Valdez" place="Barcelona" filename="Jorge-1.jpg" mdxType="Picture" />
        </Link>
      </ImageBed>
    </Col>
  </Row>
  <Row mdxType="Row">
    <Col p5="true" mdxType="Col">
      <ImageBed mdxType="ImageBed">
        <Link to="/men-s-portraits/stas-stanis" mdxType="Link">
          <Picture name="Stas Stanis" modelName="Stas Stanis, actor" place="Moscow" filename="stas-stanis-photography-by-andrey-bond.jpg" mdxType="Picture" />
        </Link>
      </ImageBed>
    </Col>
    <Col p5="true" mdxType="Col">
      <ImageBed mdxType="ImageBed">
        <Link to="/women-s-portraits" mdxType="Link">
          <Picture name="Daria" modelName="Daria Zakharova" place="Moscow" filename="daria_zakharova_photographer_andrey_bond004.jpg" mdxType="Picture" />
        </Link>
      </ImageBed>
    </Col>
    <Col p5="true" mdxType="Col">
      <ImageBed mdxType="ImageBed">
        <Link to="/men-s-portraits/simon" mdxType="Link">
          <Picture name="Simon" modelName="Simon Dmitriev" place="Moscow" filename="simon-looks-down.jpg" mdxType="Picture" />
        </Link>
      </ImageBed>
    </Col>
  </Row>
  <Row mdxType="Row">
    <Col p5="true" mdxType="Col">
      <ImageBed mdxType="ImageBed">
        <Link to="/photo-manipulation" mdxType="Link">
          <Picture name="Fog" modelName="Daria Zakharova" place="Moscow" filename="fog.jpg" mdxType="Picture" />
        </Link>
      </ImageBed>
    </Col>
    <Col p5="true" mdxType="Col">
      <ImageBed mdxType="ImageBed">
        <Link to="/men-s-portraits/nikolay" mdxType="Link">
          <Picture name="Nikolay" modelName="Nikolay Bondarenko" place="Moscow" filename="see-no-evil_square.jpg" mdxType="Picture" />
        </Link>
      </ImageBed>
    </Col>
  </Row>
    </Container>
    <AndManyMore mdxType="AndManyMore" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      